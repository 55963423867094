<template>
  <div class="NotAvailable">
    <div class="text-center" style="margin-top:50px; margin-bottom:50px">
        <font-awesome-icon icon="tools" class="icon text-4xl brand-color-dark" /><br><br>
        <p class="brand-color-dark text-2xl"><b>{{name}}</b></p>
        <p class="brand-color-dark font-bold"><b>Diese Funktion ist für Deinen Verkehrsbetrieb (noch) nicht verfügbar.</b></p>
        <p>Hilf uns bei der Entwicklung und sag uns, wie wir Dich mit dieser Funktion im Arbeitsalltag unterstützen können.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotAvailable',
  props: {
    name: String
  }
}
</script>
