<template>
  <div class="dashboard">
    <TheHeader></TheHeader>
    <SiteNav></SiteNav>
    <div class="px-3 lg:px-36 md:px-26 py-3 bg-lines bg-cover bg-fixed bg-center" >

      <NotAvailable v-if="!user.company.features.shift.available" name="Dienstplan"></NotAvailable>

      <div v-else class="md:container md:mx-auto">
        <nav class="nav-onpage " id="nav">
          <router-link
            class="nav-btn"
            to="../dienstplan/uebersicht"
            active-class="active"
          >
            <font-awesome-icon icon="route" class="icon" />
            <p class="font-bold hidden sm:block">Nächster Dienst</p>
          </router-link>
          <router-link
            class="nav-btn"
            to="../dienstplan/monatsplan"
            active-class="active"
          >
            <font-awesome-icon icon="th" class="icon" />
            <p class="font-bold hidden sm:block">Monatsübersicht</p>
          </router-link>
          <router-link
            class="nav-btn"
            to="../dienstplan/statistik"
            active-class="active"
          >
            <font-awesome-icon icon="chart-pie" class="icon" />
            <p class="font-bold hidden sm:block">Statistik</p>
          </router-link>
          <!-- <router-link
            class="nav-btn"
            to="../dienstplan/lokbuch"
            active-class="active"
          >
            <font-awesome-icon icon="edit" class="icon" />
            <p class="font-bold hidden sm:block">Lokbuch</p>
          </router-link> -->
        </nav>
        <hr class="nav-onpage-hr pb-3" />

        <div
          class="
            cards-grid
             grid-cols-1
            sm:grid-cols-3
            md:grid-cols-3
            my-5
            gap-4
            hidden
          "
          
        ><!--grid-->
          <div class="card">
            <strong class="card-title">Dienstlagen</strong>
            <hr class="card-line" />
            <div class="card-content">
              <div class="card-box">
                <div class="exchange-details">
                  <div class="title my-auto">
                    <font-awesome-icon
                      icon="dove"
                      class="icon color-shift-morning"
                    />
                    Frühdienst
                  </div>
                  <div
                    class="badge badge-white my-auto"
                    v-if="statsServiceSituations[0].count > 0"
                  >
                    {{ statsServiceSituations[0].count }}
                  </div>
                  <div class="badge badge-gray" v-else>0</div>
                </div>
                <div class="exchange-details">
                  <div class="title my-auto">
                    <font-awesome-icon
                      icon="sun"
                      class="icon color-shift-day"
                    />
                    Tagesdienst
                  </div>
                  <div
                    class="badge badge-white"
                    v-if="statsServiceSituations[1].count > 0"
                  >
                    {{ statsServiceSituations[1].count }}
                  </div>
                  <div class="badge badge-gray" v-else>0</div>
                </div>
                <div class="exchange-details">
                  <div class="title my-auto">
                    <font-awesome-icon
                      icon="cloud"
                      class="icon color-shift-middle"
                    />
                    Mitteldienst
                  </div>
                  <div
                    class="badge badge-white"
                    v-if="statsServiceSituations[2].count > 0"
                  >
                    {{ statsServiceSituations[2].count }}
                  </div>
                  <div class="badge badge-gray" v-else>0</div>
                </div>
                <div class="exchange-details">
                  <div class="title my-auto">
                    <font-awesome-icon
                      icon="cloud-moon"
                      class="icon color-shift-sunset"
                    />
                    Spätdienst
                  </div>
                  <div
                    class="badge badge-white"
                    v-if="statsServiceSituations[3].count > 0"
                  >
                    {{ statsServiceSituations[3].count }}
                  </div>
                  <div class="badge badge-gray" v-else>0</div>
                </div>
                <div class="exchange-details">
                  <div class="title my-auto">
                    <font-awesome-icon
                      icon="moon"
                      class="icon color-shift-night"
                    />
                    Nachtdienst
                  </div>
                  <div
                    class="badge badge-white"
                    v-if="statsServiceSituations[4].count > 0"
                  >
                    {{ statsServiceSituations[4].count }}
                  </div>
                  <div class="badge badge-gray" v-else>0</div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <strong class="card-title">Linien</strong>
            <hr class="card-line" />
            <div class="card-content">
              <div class="card-box">
                <div
                  class="exchange-details"
                  v-for="(s, i) in statsLines"
                  v-if="i < 5"
                >
                  <div class="title my-auto">{{ s[0] }}</div>
                  <div class="badge badge-white">{{ s[1] }}</div>
                </div>

                <div
                  class="notification"
                  v-if="Object.keys(statsLines).length === 0"
                >
                  <p>Es gibt zurzeit keine Informationen zu Deinen Linien.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <strong class="card-title">Pausenorte</strong>
            <hr class="card-line" />
            <div class="card-content">
              <div class="card-box">
                <div
                  class="exchange-details"
                  v-for="(s, i) in statsBreakLocations"
                  v-if="i < 5"
                >
                  <div class="title my-auto">{{ s[0] }}</div>
                  <div class="badge badge-white">{{ s[1] }}</div>
                </div>
                <div
                  class="notification"
                  v-if="Object.keys(statsBreakLocations).length === 0"
                >
                  <p>
                    Es gibt zurzeit keine Informationen zu Deinen Pausenorte.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- grid -->
        <div class="hidden grid-cols-3 gap-4">
          <hr class="nav-onpage-hr my-auto" />
          <p class="text-center text-shadow">
            <router-link
              to="../dienstplan/statistik"
              class="link cursor-pointer text-blue-400"
              ><font-awesome-icon icon="chart-pie" class="icon" /> <br class="block sm:hidden">Detaillierte Statistik anzeigen</router-link
            >
          </p>
          <hr class="nav-onpage-hr my-auto" />
        </div>

        

        <router-view class="space"></router-view>
      </div>
    </div>
    <!-- <div class="container" v-else>
      Keine Daten vorhanden... Time to sync my friend 😏
    </div> -->
  </div>
</template>


<script>
import SiteNav from "@/components/TheNavigation";
import TheHeader from "@/components/TheHeader";
import NotAvailable from "@/components/NotAvailable";

import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    SiteNav,
    TheHeader,
    NotAvailable
  },
  data() {
    return {
      noData: false,
      statsLines: {},
      statsBreakLocations: {},
      statsServiceSituations: [
        {
          text: '<i class="fas fa-dove" v-b-tooltip.hover title="Frühdienst"></i> Frühdienst',
          count: 0,
        },
        {
          text: '<i class="fas fa-sun" v-b-tooltip.hover title="Tagesdienst"></i> Tagesdienst',
          count: 0,
        },
        {
          text: '<i class="fas fa-cloud" v-b-tooltip.hover title="Mitteldienst"></i> Mitteldienst',
          count: 0,
        },
        {
          text: '<i class="fas fa-cloud-moon" v-b-tooltip.hover title="Spätdienst"></i> Spätdienst',
          count: 0,
        },
        {
          text: '<i class="fas fa-moon" v-b-tooltip.hover title="Nachtdienst"></i> Nachtdienst',
          count: 0,
        },
      ],
    };
  },
  methods: {
    createStatsLines(shift) {
      this.statsLines = {};

      for (let index = 0; index < shift.length; index++) {
        // if (this.isDateNextMonth(doc.date.toDate())) {

        if (shift[index].shift.length == 0) {
          continue;
        }

        shift[index].shift.forEach((doc) => {
          if (doc.typ == 0) {
            var l = doc.line.split(", ");
            if (l.length > 1) {
              // console.log(doc.line, true);
              for (var i in l) {
                // console.log(l[i]);
                if (this.statsLines[l[i]] == null) {
                  this.statsLines[l[i]] = 0;
                }
                this.statsLines[l[i]]++;
              }
            } else {
              if (this.statsLines[doc.line] == null) {
                var l = doc.line;
                if (l.length == 0) {
                  l = shift[index].header_nr;
                }
                // console.log("l:" + l);
                this.statsLines[l] = 0;
              }
              this.statsLines[l]++;
            }
          }
        });
        // }
      }

      var sortable = [];
      for (var key in this.statsLines) {
        if (this.statsLines.hasOwnProperty(key)) {
          sortable.push([key, this.statsLines[key]]);
        }
      }

      // sort items by value
      sortable.sort(function (a, b) {
        return b[1] - a[1]; // compare numbers
      });

      this.statsLines = sortable;
    },

    createStatsBreakLocations(shift) {
      this.statsBreakLocations = {};

      shift.forEach((doc) => {
        // if (this.isDateNextMonth(doc.date.toDate())) {
        doc.shift.forEach((doc) => {
          if (doc.typ == 1) {
            doc.start_location = doc.start_location.replace("S - ", "");
            if (this.statsBreakLocations[doc.start_location] == null) {
              this.statsBreakLocations[doc.start_location] = 0;
            }
            this.statsBreakLocations[doc.start_location]++;
          }
        });
        // }
      });

      var sortable = [];
      for (var key in this.statsBreakLocations)
        if (this.statsBreakLocations.hasOwnProperty(key))
          sortable.push([key, this.statsBreakLocations[key]]); // each item is an array in format [key, value]

      // sort items by value
      sortable.sort(function (a, b) {
        return b[1] - a[1]; // compare numbers
      });

      this.statsBreakLocations = sortable;
    },

    createStatServiceSituations(val) {
      this.statsServiceSituations[0].count = 0;
      this.statsServiceSituations[1].count = 0;
      this.statsServiceSituations[2].count = 0;
      this.statsServiceSituations[3].count = 0;
      this.statsServiceSituations[4].count = 0;

      for (let index = 0; index < val.length; index++) {
        if (val[index].time_start == null) {
          continue;
        }

        var h = val[index].time_start.split(":")[0];
        if (h < 7) {
          this.statsServiceSituations[0].count++;
        } else if (h < 12) {
          this.statsServiceSituations[1].count++;
        } else if (h < 15) {
          this.statsServiceSituations[2].count++;
        } else if (h < 20) {
          this.statsServiceSituations[3].count++;
        } else {
          this.statsServiceSituations[4].count++;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch("fetchUserProfile");
    this.$store.dispatch("fetchShifts",true);
  },
  watch: {
    shifts: function (val) {
      // console.log("watch");
      if (val.length > 0) {
        this.createStatsLines(val);
        this.createStatsBreakLocations(val);
        this.createStatServiceSituations(val);
      } else {
        // console.log("SHIFT EMPTY");
        this.noData = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      shifts: "getShiftsForCurrentMonth"
      // shifts: "getShifts",
      // nextShift: "getNextShifts"
    }),
  },
};
</script>

<style scoped>
.cards-grid .card {
  @apply rounded-lg bg-blue-900 flex flex-col justify-start;
}

.cards-grid .card .card-title {
  @apply text-center p-2 sm:p-3 text-lg font-bold;
}

.cards-grid .card .card-line {
  @apply w-11/12 sm:w-10/12 mx-auto;
}

.cards-grid .card .card-content {
  @apply my-4 mx-4 sm:mx-6;
}

/* .card-box {
    @apply  divide-y-2 divide-gray-800 pb-2;
  } */
</style>